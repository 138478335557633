.root {
  background-size: 100% auto;
  /* background-color: #2e2e2e; */
  background-position: top center;
  /* margin-bottom: 40px; */
}

.header-bar {
  background: #101010;
  /* height: 70px; */
}

.header-bar .header-sublogo {
  color: #fff;
  text-align: right;
  font-size: 14px;
  font-weight: 40;
  padding: 6px 0;
  float: left;
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 36px;
  font-weight: bold;
}

.header-bar .header-sublogo .last-letter {
  color: #009DDB;
}

.title {
  background: url(/img/background.png);
  padding-top: 70px;
  padding-bottom: 70px;
  background-size: cover;
  background-position-y: 20%;
}

.title h3 {
  text-align: center;
  color: #fff;
  font-size: 40px;
  font-weight: 100;

  font-family: "Raleway", sans-serif;
  /* text-shadow: #000000 1px 3px 4px; */
}

.header-logo {  
  flex: 1;
  display: flex;
  max-height: 80px;
}

.header-logo img {
  max-width: 70px;
  margin-top: 5px;
  /* box-shadow: #000000 1px 2px 4px;
  border-radius: 50%; */
}

.container-logo img {
  max-width: 220px;
}

.panels {
  /* background-color: #fff; */
  padding: 40px 0px 40px 0px;
}

.img-responsive {
  width: 100%;
}

.img-responsive-90 {
  width: 90%;
}

.img-responsive-80 {
  width: 80%;
}

.img-responsive-50 {
  width: 50%;
}

.img-responsive-45 {
  width: 40%;
}

.img-responsive-40 {
  width: 40%;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.feeback-text {
  text-align: center;
  height: 100vh;
}

.feeback {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: #2e2e2e; */
}

.feeback .feeback-center {
  color: #ffffff;
  font-size: 22px;
  text-align: center;
  flex: 1;
  /* margin: 0;
  padding: 0;  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  */
}

/* .footer {
  background-color: #2e2e2e;
  min-height: 80px;
  width: 100%;
} */


@media only screen and (max-width: 600px) {
  .title {
    padding: 40px 20px;
    width: 100%;
  }
  .title h3 {
    font-size: 24px;
  }
}