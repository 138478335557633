body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Raleway';
  background: #101010;
}

* {
  font-family: "Raleway", sans-serif;
}
