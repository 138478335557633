.container-apps {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.container-apps .item {
    /* width: 180px; */
    height: 150px;
    margin-top: 20px;
    border-radius: 10px;
    flex:1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    text-decoration: none;
    cursor: pointer;
}

.container-apps .item.item-disabled {
    cursor: initial;
}

.container-apps .item .content {
    padding: 20px 10px 0;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 24px ;
    margin-bottom: 0;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-apps .item .content-subtitle {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
    font-size: 14px;
    color: #FFF;
}

.app-web, .app-mobile {
    position: relative;
}

.app-web::after, .app-mobile::after {
    content: "WEB";
    font-size: 12px;
    position: absolute;
    right: 0;
    bottom: -14px;
}

.app-mobile::after {
    content: "MOBILE";
}

@media only screen and (max-width: 980px) {
    .container-apps {
        grid-template-columns: 25% 25% 25% 25%;
        margin-top: 20px;
        margin-bottom: 40px;
    }
}


@media only screen and (max-width: 760px) and (min-width: 370px) {
    .container-apps {
        grid-template-columns: 50% 50%;
        margin-top: 0;
        margin-bottom: 20px;
    }

    .container-apps .item:nth-child(odd) {
        margin-right: 10px;
    }

    .container-apps .item:nth-child(even) {
        margin-left: 10px;
        margin-right: 0;
    }
}

@media only screen and (max-width: 370px) {
    .container-apps {
        grid-template-columns: 100%;
        margin-top: 0;
        margin-bottom: 20px;
    }

    .container-apps .item {
        margin-left: 0;
        margin-right: 0;
    }
}